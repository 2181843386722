import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "menu", "toggle" ]
  static values = {
    desktopWidth: String
  }

  connect() {
    this.menuTarget.classList.add(`lg:w-${this.desktopWidthValue}`)
  }

  toggle() {
    this.menuTarget.classList.toggle('hidden')
    this.contentTarget.classList.toggle(`lg:pl-${this.desktopWidthValue}`)
    for (const icon of this.toggleTarget.children) {
      icon.classList.toggle('hidden')
    }
  }
}
